import React from "react";
import { Layout } from "../components";
import { StaticQuery, graphql, Link } from "gatsby";

const CaseStudy = ({ caseStudyPosts }) => {
  return (
    <Layout
      seo={{
        title: "Case Study",
        href: "pl",
        lang: "pl"
      }}
    >
      <section className="container-fluid page_case_study">
        <h1>Case study</h1>
        <div className="row">
          {caseStudyPosts.edges.map(({ node }) => (
            <div className="col-lg-4 col-md-6" key={node.id}>
              <Link to={`/case-study/${node.slug}`} className="case_study_tile">
                <h3>
                  <div dangerouslySetInnerHTML={{ __html: node.title }} />
                </h3>
                <div
                  className="case_study_tile-back"
                  style={{
                    backgroundImage: `url(${
                      node.featured_media ? node.featured_media.source_url : ""
                    })`
                  }}
                ></div>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      {
        caseStudyPosts: allWordpressWpCasestudy {
          edges {
            node {
              id
              title
              slug
              featured_media {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => <CaseStudy {...data} />}
  />
);
